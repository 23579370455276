import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export class CountyField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            (param) => {
                return new Promise((resolve) => {
                    ApiService.get('counties').then(({ data }) => {
                        if (param?.search) {
                            const result = data.filter(item => item.county.toLowerCase().startsWith(param.search));
                            resolve(result);
                        }
                        resolve(data);
                    });
                });
            },
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.id,
                    text: record.county,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
